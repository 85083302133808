<template>
    <b-modal
        :id="type"
        :title="$t('staff.invite')"
        size="lg"
        hide-footer
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="setData"
        @hide="onClose"
    >
        <b-container fluid>
            <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <b-button
                            v-b-toggle.accordion-c
                            variant="primary"
                            disabled
                            ><b-row class="px-2"
                                ><i class="fi flaticon-business-1 mr-2"></i
                                >{{ $t("companies.company") }}:</b-row
                            >
                            <b-row class="px-2">
                                <strong>{{ company.Name }}</strong></b-row
                            >
                        </b-button>
                    </b-card-header>
                    <b-collapse
                        id="accordion-c"
                        accordion="my-accordion"
                        role="tabpanel"
                        :visible="false"
                    >
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1 card-with-dropdown">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <b-button block v-b-toggle.accordion-p variant="primary"
                            ><b-row class="px-2"
                                >
                                <!-- <img :src="'/images/person-big_MyZesec_140dp.png'" class="mr-2"/> -->
                                <i class="fi flaticon-user mr-2"></i>{{ $t("staff.name") }}:</b-row
                            >
                            <b-row class="px-2">
                                <strong>{{ userName }}</strong></b-row
                            ></b-button
                        >
                    </b-card-header>
                    <b-collapse
                        id="accordion-p"
                        accordion="my-accordion"
                        role="tabpanel"
                        visible
                    >
                        <b-card-body>
                            <b-form-group>
                                <b-form-input
                                    v-model="$v.invite.Name.$model"
                                    :state="validateState('Name')"
                                    id="employeeName"
                                    :placeholder="$t('staff.name')"
                                />
                                <b-form-invalid-feedback
                                    id="employeeName-feedback"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_range", {
                                            range: "3-50",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group>
                                <vue-phone-number-input
                                    v-model="$v.invite.PhoneNumber.$model"
                                    id="employeePhone"
                                    :error="!validPhone && submitted"
                                    :translations="$t('phoneInput')"
                                    @update="validatePhone"
                                    required
                                    valid-color="#28a745"
                                    error-color="#dc3545"
                                    fetch-country
                                    type="text"
                                />
                                <span id="employeePhone-feedback"  v-if="!validPhone && submitted"  style="color: #e63857;  font-size: 0.8125rem">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("errors.validation_phone_number") }}
                                </span>
                            </b-form-group>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <b-button
                            block
                            v-b-toggle.accordion-r
                            variant="primary"
                            :disabled="!invite.Name || invite.Name.length < 3 || invite.Name.length > 50 || !validPhone"
                            ><b-row class="px-2"
                                ><i class="fe fe-settings mr-2"></i
                                >{{ $t("staff.role") }}:</b-row
                            >
                            <b-row class="px-2">
                                <strong>{{ roleName }}</strong></b-row
                            ></b-button
                        >
                    </b-card-header>
                    <b-collapse
                        id="accordion-r"
                        accordion="my-accordion"
                        role="tabpanel"
                    >
                        <b-card-body>
                            <b-form-group>
                                <b-form-select
                                    v-model="$v.invite.RoleType.$model"
                                    :state="validateState('RoleType')"
                                    @change="roleSelected"
                                    id="role"
                                    value-field="Id"
                                    :options="rolesOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="role-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("staff.role_select")
                                    }}
                                </b-form-invalid-feedback
                                >
                            </b-form-group>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <b-button
                            block
                            v-b-toggle.accordion-l
                            variant="primary"
                            :disabled="!locationManagerSelected || !keyManagerSelected"
                            ><b-row class="px-2"
                                ><i class="fi flaticon-business mr-2"></i
                                >{{ $t("locations.location") }}:</b-row
                            >
                            <b-row class="px-2">
                                <strong>{{ locationName }}</strong></b-row
                            ></b-button
                        >
                    </b-card-header>
                    <b-collapse
                        id="accordion-l"
                        accordion="my-accordion"
                        role="tabpanel"
                    >
                        <b-card-body>
                            <b-form-group>
                                <!-- <b-form-select
                                    v-model="$v.invite.LocationId.$model"
                                    :state="validateState('LocationId')"
                                    id="LocationId"
                                    v-if="locationsListOptions.length > 15"
                                    :options="locationsListOptions"
                                    onfocus='this.size=10, this.height=2'
                                    onchange='this.size=10, this.height=2, this.blur();'
                                    onblur='this.size=10, this.height=2'
                                    onfocusout='this.size=null;'
                                ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.invite.LocationId.$model"
                                    :state="validateState('LocationId')"
                                    id="LocationId"
                                    :options="locationsListOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="LocationId-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("staff.location_select")
                                    }}
                                </b-form-invalid-feedback
                                >
                            </b-form-group>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>

            <b-row>
                <b-col>
                    <b-button class="input-field" @click.prevent.stop="inviteEmployee">{{
                        $t("staff.send_invite")
                    }}</b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

import { M_COMPANY_INVITE_FORM } from "@/components/modals/types";
import {
    ACL_CREATE_LOCATION_MANAGER_ROLE,
    ACL_INVITE_TO_COMPANY_ROLE,
    ACL_INVITE_TO_LOCATION_ROLE,
} from "@/store/types/acl";
import {
    COMPANY_GET_INVITES,
    COMPANY_GET_EMPLOYEES,
} from "@/store/types/companies";
import { LOCATIONS_GET_COMPANY_LOCATIONS } from "@/store/types/locations";
import { CLEAR_MODAL_DATA } from "@/store/types/global";

import { validationMixin } from "vuelidate";
import {
    definedRoles,
    definedRolesArray,
    definedKeyRolesIdsArray,
    ROLE_COMPANY_OWNER,
    ROLE_COMPANY_MANAGER,
    ROLE_LOCATION_MANAGER,
    ROLE_DEVICE_INSTALLER,
    ROLE_KEY_MANAGER,
} from "@/acl/roles";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import VuePhoneNumberInput from "vue-phone-number-input";

import aclController from "@/api/acl";

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_COMPANY_INVITE_FORM,
            userRole: "",
            inviteTemplate: {
                Name: "",
                PhoneNumber: "",
                RoleType: null,
                CompanyId: null,
                LocationId: null,
            },
            validPhone: false,
            fullPhone: null,
            submitted: false,
            invite: {},
        };
    },
    props: ["company"],
    validations: {
        invite: {
            Name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50),
            },
            PhoneNumber: {
                required,
                phone: function () {
                    return this.validPhone;
                },
            },
            RoleType: { required },
            LocationId: {
                location: function () {
                    return this.invite.RoleType !==
                        definedRoles[ROLE_COMPANY_MANAGER].Id
                        ? this.invite.LocationId != null
                        : true;
                },
            },
        },
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getCompanyLocations", "getUserRoles"]),
        locationsListOptions() {
            return [
                {
                    value: null,
                    text: this.$t("staff.location_select"),
                    disabled: true,
                },
                ...this.getCompanyLocations
                    .filter((item) => item.CompanyId == this.company.Id)
                    .sort((i1, i2) => {
                        return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                    })
                    .map((item) => {
                        return { value: item.Id, text: item.Name };
                    }),
            ];
        },
        rolesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("staff.role_select"),
                    disabled: true,
                },
                ...definedRolesArray().filter(
                    (item) =>{
                        /* this.$can("invite", item) &&
                        !definedKeyRolesIdsArray().includes(item.Id) */
                        if(item.value == ROLE_COMPANY_OWNER){
                            item.text = this.$t("permissions.role_owner");
                        }else if(item.value == ROLE_COMPANY_MANAGER){
                            item.text = this.$t("permissions.role_cm");
                        }else if(item.value == ROLE_LOCATION_MANAGER){
                            item.text = this.$t("permissions.role_lm");
                        }else if(item.value == ROLE_KEY_MANAGER){
                            item.text = this.$t("permissions.role_km");
                        }else if(item.value == ROLE_DEVICE_INSTALLER){
                            item.text = this.$t("permissions.role_installer");
                        }

                        if(this.userRole == "CompanyOwner"){
                            return (
                                // this.$can("invite", item) &&
                                [
                                    ROLE_COMPANY_MANAGER,
                                    ROLE_KEY_MANAGER,
                                    ROLE_DEVICE_INSTALLER,
                                    ROLE_LOCATION_MANAGER,
                                ].includes(item.value)
                            );
                        }else if(this.userRole == "CompanyManager"){
                            return (
                                // this.$can("invite", item) &&
                                [
                                    ROLE_COMPANY_MANAGER,
                                    ROLE_KEY_MANAGER,
                                    ROLE_DEVICE_INSTALLER,
                                    ROLE_LOCATION_MANAGER,
                                ].includes(item.value)
                            );
                        }else if(this.userRole == "LocationManager"){
                            return (
                                // this.$can("invite", item) &&
                                [
                                    ROLE_KEY_MANAGER,
                                    ROLE_DEVICE_INSTALLER,
                                ].includes(item.value)
                            );
                        }else if(this.userRole == "KeyManager"){
                            return (
                                // this.$can("invite", item) &&
                                [
                                    ROLE_DEVICE_INSTALLER,
                                ].includes(item.value)
                            );
                        }
                    }),
            ];
        },
        locationManagerSelected() {
            return [
                definedRoles[ROLE_LOCATION_MANAGER].Id,
                definedRoles[ROLE_DEVICE_INSTALLER].Id,
                definedRoles[ROLE_KEY_MANAGER].Id,
            ].includes(this.invite.RoleType);
        },
        keyManagerSelected() {
            return [
                definedRoles[ROLE_DEVICE_INSTALLER].Id,
                definedRoles[ROLE_KEY_MANAGER].Id,
            ].includes(this.invite.RoleType);
        },
        companyManagerSelected() {
            return (
                this.invite.RoleType == definedRoles[ROLE_COMPANY_MANAGER].Id
            );
        },
        roleName() {
            return this.invite.RoleType
                ? definedRolesArray().find(
                      (item) => item.Id == this.invite.RoleType
                  ).text
                : this.$t("staff.role_select");
        },
        locationName() {
            return this.invite.LocationId
                ? this.locationsListOptions.find(
                      (item) => item.value == this.invite.LocationId
                  ).text
                : this.$t("staff.location_select");
        },
        userName() {
            return this.invite.Name && this.validPhone
                ? this.invite.Name
                : this.$t("staff.user_select");
        },
    },
    created() {
        this.setData();
        this.getLoggedInUserRoles();
    },
    methods: {
        getLoggedInUserRoles() {
            /* this.getUserRoles.filter((item) => item.CompanyId == this.$route.params.companyId)
            .map((item) => {
                this.userRole = item.Name;
            }); */
            this.getUserRoles.filter((item) => item.CompanyId == this.$route.params.companyId)
            .map((item) => {
                if(item.Name == "CompanyOwner" || item.Name == "CompanyManager" || item.Name == "LocationManager" || item.Name == "KeyManager"){
                    return this.userRole = item.Name;
                }else{
                    return
                }
            });
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.invite[name];
            return $dirty && this.submitted ? !$error : null;
        },
        validatePhone(phone) {
            this.validPhone = phone.isValid;
            this.fullPhone = phone;
        },

        roleSelected(roleId) {
            if (
                [
                    definedRoles[ROLE_LOCATION_MANAGER].Id,
                    definedRoles[ROLE_DEVICE_INSTALLER].Id,
                    definedRoles[ROLE_KEY_MANAGER].Id,
                ].includes(roleId)
            ) {
                this.$root.$emit("bv::toggle::collapse", "accordion-l");
            }
        },

        inviteEmployee: function () {
            this.submitted = true;
            this.$v.invite.$touch();

            if (this.$v.invite.$anyError) {
                return;
            }
            if (this.companyManagerSelected) {
                this._createCompanyManagerRole();
            } else if (this.locationManagerSelected) {
                this._createLocationManagerRole();
            } else if (this.keyManagerSelected) {
                this._createLocationManagerRole();
            }
        },

        _createCompanyManagerRole() {
            let payload = {
                ...this.invite,
                ...{
                    PhoneNumber: this.fullPhone.formattedNumber,
                },
            };
            this.$bvModal.hide(this.type);
            this.$store
                .dispatch(ACL_INVITE_TO_COMPANY_ROLE, payload)
                .then(() => {
                    this.$store.dispatch(COMPANY_GET_INVITES, this.company.Id);
                    this.$store.dispatch(
                        COMPANY_GET_EMPLOYEES,
                        this.company.Id
                    );
                    this.$session.set("popUpClose", true);
                });
        },

        _createLocationManagerRole() {
            let payload = {
                ...this.invite,
                ...{
                    PhoneNumber: this.fullPhone.formattedNumber,
                },
            };
            this.$bvModal.hide(this.type);

            this.$store
                .dispatch(ACL_INVITE_TO_LOCATION_ROLE, payload)
                .then(() => {
                    this.$store.dispatch(COMPANY_GET_INVITES, this.company.Id);
                    this.$store.dispatch(
                        COMPANY_GET_EMPLOYEES,
                        this.company.Id
                    );
                    this.$session.set("popUpClose", true);
                });
        },

        setData() {
            this.invite = {
                ...this.inviteTemplate,
                ...{ CompanyId: this.company.Id },
            };
            this.$store.dispatch(
                LOCATIONS_GET_COMPANY_LOCATIONS,
                this.company.Id
            );
        },
        onClose() {
            this.invite = { ...this.inviteTemplate };
            this.submitted = false;
            this.$v.invite.$reset();
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
    components: {
        "vue-phone-number-input": VuePhoneNumberInput,
    },
};
</script>
