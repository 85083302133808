<template>
    <b-modal
        :id="type"
        :ok-title="$t('buttons.delete')"
        :cancel-title="$t('buttons.cancel')"
        :title="$t('modals.title_remove_user')"
        dialog-class="modal-dialog-vertical"
        modal-class="fixed-right"
        ok-variant="outline-danger"
        @hide="onClose"
        @ok="deleteUser"
        @show="setData"
    >
        <b-container v-if="type == getModalType" fluid>
            <b-row v-if="getModalData.user.Id">
                <b-col cols="12">
                    {{
                        $t("modals.text_remove_user", {
                            userName:
                                getModalData.user.FirstName +
                                " " +
                                getModalData.user.LastName,
                        })
                    }}
                    <span class="bold-font-style">{{
                            $t("modals.are_you_sure")
                        }}</span>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col cols="12">
                    {{
                        $t("modals.text_remove_user", {
                            userName:
                                getModalData.user.User.FirstName +
                                " " +
                                getModalData.user.User.LastName,
                        })
                    }}
                    <span class="bold-font-style">{{
                            $t("modals.are_you_sure")
                        }}</span>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import {COMPANY_USER_DELETE, COMPANY_GET_EMPLOYEES,} from "@/store/types/companies";
import {M_COMPANY_USER_DELETE} from "@/components/modals/types";
import {CLEAR_MODAL_DATA} from "@/store/types/global";

export default {
    data() {
        return {
            type: M_COMPANY_USER_DELETE,
            user: {},
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType"]),
    },

    methods: {
        setData() {        
            if(this.getModalData.user.Id){
                this.user = {...this.getModalData.user};
                if (this.getModalData.user.FirstName == null || this.getModalData.user.FirstName == "null" || this.getModalData.user.FirstName == "") {
                    this.getModalData.user.FirstName = '';
                }
                if (this.getModalData.user.LastName == null || this.getModalData.user.LastName == "null" || this.getModalData.user.LastName == "") {
                    this.getModalData.user.LastName = '';
                }
            }else{
                this.user = {...this.getModalData};
                if (this.getModalData.user.User.FirstName == null || this.getModalData.user.User.FirstName == "null" || this.getModalData.user.User.FirstName == "") {
                    this.getModalData.user.User.FirstName = '';
                }
                if (this.getModalData.user.User.LastName == null || this.getModalData.user.User.LastName == "null" || this.getModalData.user.User.LastName == "") {
                    this.getModalData.user.User.LastName = '';
                }
            }
        },
        deleteUser() {
            /* if(this.getModalData.user.User.Id){
                this.getModalData.user.User["CompanyId"] = this.$route.params.companyId;
                 console.log("ewrwe:"+this.getModalData.user.User["Id"])
            } */
            if(this.getModalData.user.Id){
                this.$store
                    .dispatch(COMPANY_USER_DELETE, {
                        ...this.getModalData.user,
                    })
                    .then(() => {
                        this.$store.dispatch(
                                COMPANY_GET_EMPLOYEES,
                                this.$route.params.companyId
                            );
                        this.$session.set("popUpClose", true);
                    });
             }else{
                this.getModalData.user.User["CompanyId"] = this.$route.params.companyId;
                this.getModalData.user.User["Id"] = this.$route.params.userId;
                    this.$store
                    .dispatch(COMPANY_USER_DELETE, {
                        ...this.getModalData.user.User,
                    })
                    .then(() => {
                        this.$store.dispatch(
                                COMPANY_GET_EMPLOYEES,
                                this.$route.params.companyId
                            );
                        this.$session.set("popUpClose", true);
                    });
            } 
        },
        onClose() {
            this.user = null;
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>
